var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.users,"fields":_vm.fields,"store":"auth.users","resource":"/admin/users","enterable":"","deletable":""},on:{"click-clear-filter":_vm.clearFilter,"click-create":function($event){_vm.showModalCreate = true}},scopedSlots:_vm._u([{key:"role_id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.role)?_c('SMessageRole',{attrs:{"id":item.role_id,"content":item.role.name}}):_vm._e()],1)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.name,"noTranslate":""}})],1)]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.email,"noTranslate":""}})],1)]}},{key:"tel",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.tel,"noTranslate":""}})],1)]}},{key:"organization_id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.organization)?_c('TMessage',{attrs:{"content":item.organization.name,"noTranslate":""}}):_vm._e()],1)]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.created_at,"small":""}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"name-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.name},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "name", $event)},_vm.filterChange]}})]},proxy:true},{key:"email-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.email},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "email", $event)},_vm.filterChange]}})]},proxy:true},{key:"tel-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.tel},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "tel", $event)},_vm.filterChange]}})]},proxy:true},{key:"role_id-filter",fn:function(){return [_c('SSelectRole',{attrs:{"value":_vm.filter.role_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "role_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"organization_id-filter",fn:function(){return [_c('SSelectOrganization',{attrs:{"value":_vm.filter.organization_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "organization_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"created_at-filter",fn:function(){return [_c('TInputDateTimeRange',{attrs:{"value":_vm.dateRange},on:{"update:value":[function($event){_vm.dateRange=$event},_vm.setCreatedAtFilter]}})]},proxy:true}])}),_c('SModalCreateUser',{attrs:{"show":_vm.showModalCreate},on:{"update:show":function($event){_vm.showModalCreate=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }